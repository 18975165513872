import Image from 'next/image';
import { useCreateAccountProvider } from '@/lib/context';
import './index.less';

export default function Header() {
  const { showSignupDialog } = useCreateAccountProvider();

  const images = [
    {
      src: 'sourcing-manufacturing.png',
      alt: 'Simplify Sourcing & Production',
    },
    {
      src: 'order-fulfillment.png',
      alt: 'Simplify Order Fulfillment',
    },
    {
      src: 'marketing.png',
      alt: 'Simplify Marketing',
    },
    {
      src: 'sales.png',
      alt: 'Simplify Sales',
    },
  ];

  return (
    <div className="homepage-header" id="homepage-header">
      <div className="banner">
        <div className="banner-left">
          <span className="title">An e-commerce toolkit to power your brand.</span>
          <span className="subtitle">Pietra | One-stop platform for your e-commerce brand</span>
          <div className="buttons">
            <span className="button link" onClick={showSignupDialog}>
              Create Free Account
            </span>
          </div>
        </div>
        <div className="banner-right">
          {images.map((img: any, i) => (
            <Image
              key={`hero-image-${i}`}
              width={600}
              height={600}
              quality={100}
              src={`/images/home/${img.src}`}
              alt={img.alt}
              className="hero-image"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
